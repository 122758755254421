import React from 'react';
import Layout from '../Layout';
import { Section } from '../Section';
import useStoryblok from '../../../../lib/storyblok';
import Bloks from '../../../atoms/Bloks';

type Props = {
  location: Location;
  pageContext: any;
};

const ProductPageTemplate: React.FC<Props> = ({ location, pageContext }) => {
  const { content } = useStoryblok(pageContext.story, location);

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section noGrid>
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections} />
    </Layout>
  );
};

export default ProductPageTemplate;
